import React from 'react';
import tw from 'tailwind.macro';

const Header = () => {
  return (
    <header css={tw`flex flex-col justify-center`}>
      <h1 css={tw`font-serif font-medium text-4xl text-center`}>AIW Alton, Inc.</h1>
      <strong css={tw`uppercase text-gray-dark text-xl text-center font-sans`}>
        Established 1930
        <br />
        MFR. CODE 84027
      </strong>
    </header>
  );
};
export default Header;
