/* eslint-disable */

import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import getConfig from 'next/config';
import Head from 'next/head';
import { css } from '@emotion/core';

import Header from './Header';
import Footer from './Footer';
import Contain from './Contain';
import * as CONSTANTS from '../constant';

const { publicRuntimeConfig } = getConfig();

const Layout = props => {
  const title = props.description || 'AIW Alton, Inc providing part to US Deptartment of Defense for 90years';
  return (
    <>
      <Head>
        <title>{props.title && props.title !== '' ? `${props.title}` : ''}</title>
        <link rel="canonical" href="https://www.aiwalton.com" />
        <meta name="description" content={title} />
        <meta name="robots" content={props.metaRobots || 'index,follow'} />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400;1,500&family=Ubuntu:ital,wght@0,400;0,500;1,400;1,500&display=swap"
          rel="stylesheet"
        ></link>
      </Head>

      <main
        id="pageContent"
        role="main"
        css={css`
          font-size: 16px;
          transition: transform 0.3s ease;
        `}
      >
        <Header />
        <Contain>{props.children}</Contain>
        <Footer />
      </main>
    </> //
  );
};

export default withRouter(Layout);
