import React from 'react';
import tw from 'tailwind.macro';

// STYLES
import * as Style from './footer.style';

const Footer = () => {
  return (
    <footer>
      <Style.Address>
        <p css={tw`font-sans font-medium text-lg text-gray-darker text-center`}>
          545 Spring St
          <br />
          Unit D&E
          <br />
          Windsor Locks, CT 06096
        </p>
      </Style.Address>
    </footer>
  );
};
export default Footer;
