export const BREAKPOINTS = {
  xs: '414px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
};

export const COLORS = {
  WHITE: '#ffffff',
  GRAY_LIGHTER: '#f4f4f4',
  GRAY_LIGHT: 'rgba(77, 77, 77, 1)',
  GRAY: '#a2a9ad',
  GRAY_DARK: '#727779',
  GRAY_DARKER: '#313131',
  BLACK: '#231f20',
  RED: '#e50914',
  RED_DARK: '#9e1d1f',
  RED_DARKER: '#e71316',
  GREEN: '#22ba7c',
  GRAY_MEDIUM: '#aaaaaa',
  GRAY_BIGGER: '#333333',
  GRAY_LIGHTMEDIUM: '#dadada',
  SHADOW_BLACK: 'rgba(0,0,0,.24)',
  ORANGE_LIGHT: '#ffa600',
  BLUE_LIGHTER: '#207498',
};
