/* eslint-disable react/jsx-no-target-blank */
import { faAt, faFax, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import tw from 'tailwind.macro';
//COMPONENTS
import Layout from '../components/Layout';

const AutoPlay = withAutoplay(AwesomeSlider);

const Home = () => {
  const availAnim = ['foldOutAnimation', 'cubeAnimation', 'fallAnimation'];
  const [anim, setAnim] = useState('foldOutAnimation');

  const [salesEmail, setSalesEmail] = useState('');
  const [contractEmail, setContractEmail] = useState('');
  const handleAnim = () => {
    // eslint-disable-next-line no-bitwise
    const randAnim = availAnim[(availAnim.length * Math.random()) | 0];
    setAnim(randAnim);
  };

  const showEmail = type => {
    if (type === 'sales') {
      setSalesEmail('sales@aiwalton.com');
    } else {
      setContractEmail('contract@aiwalton.com');
    }
  };

  useEffect(() => {
    // console.log('ZIPCODE: ', zipcode);
  }, []);

  return (
    <Layout>
      <div>
        <div css={tw`w-6/12 mx-auto mt-4 flex justify-between`}>
          <a
            css={tw`font-sans font-normal text-sm text-gray-darker hover:text-red focus:shadow-outline`}
            href="tel:860.683.0731"
            target="_blank"
          >
            <i css={tw`block float-left w-4 mr-1`} aria-hidden="true">
              <FontAwesomeIcon icon={faPhone} />
            </i>
            860-683-0731
          </a>
          <a
            css={tw`font-sans font-normal text-sm text-gray-darker hover:text-red focus:shadow-outline`}
            href="tel:860-683-0114"
            target="_blank"
          >
            <i css={tw`block float-left w-4 mr-1`} aria-hidden="true">
              <FontAwesomeIcon icon={faFax} />
            </i>
            860-683-0114
          </a>
        </div>
        <div css={tw`w-6/12 mx-auto mt-4`}>
          <AutoPlay
            animation={anim}
            play
            cancelOnInteraction={false}
            interval={3000}
            bullets={false}
            organicArrows={false}
            mobileTouch
            onTransitionEnd={handleAnim}
          >
            <div data-src="/images/drill-444493_800.jpg" />
            <div data-src="/images/drilling-84848_800.jpg" />
            <div data-src="/images/milling-1151344_800.jpg" />
            <div data-src="/images/milling-1151358_800.jpg" />
            <div data-src="/images/tube-bender-2819137_800.jpg" />
          </AutoPlay>
        </div>
        <div css={tw`w-6/12 mx-auto mt-4`}>
          <p css={tw`font-serif font-medium text-black text-xl`}>
            AIW-Alton is a general manufacturer of military equipment for various Aviation, Army and Naval applications
            directly to the U.S. Government.
          </p>
          <p css={tw`font-serif font-normal text-base text-black text-center mt-4`}>
            For sales requests please send email to:
            <a
              css={tw`font-medium text-black hover:text-red focus:shadow-outline`}
              href={`mailto:${salesEmail}`}
              target="_blank"
              onMouseEnter={() => showEmail('sales')}
              onFocus={() => showEmail('sales')}
            >
              {salesEmail}
              {salesEmail === '' && (
                <i css={tw`inline-block w-3 ml-1`} aria-hidden="true">
                  <FontAwesomeIcon icon={faAt} />
                </i>
              )}
            </a>
          </p>
          <p css={tw`font-serif font-normal text-base text-black text-center`}>
            For information concerning current contracts / orders email:
            <a
              css={tw`font-medium text-black hover:text-red focus:shadow-outline`}
              href={`mailto:${contractEmail}`}
              target="_blank"
              onMouseEnter={showEmail}
              onFocus={showEmail}
            >
              {contractEmail}
              {contractEmail === '' && (
                <i css={tw`inline-block w-3 ml-1`} aria-hidden="true">
                  <FontAwesomeIcon icon={faAt} />
                </i>
              )}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default Home;
