import styled from '@emotion/styled';

import { BREAKPOINTS } from '../constant';

const Contain = styled.article(props => {
  const result = {
    position: 'relative',
    maxWidth: props.less ? '1130px' : '1300px',
    padding: props.noPadding ? '0px' : '0 50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
    [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: props.less ? '1110px' : '1280px',
      padding: props.noPadding ? '0px' : '0 40px',
    },
    [`@media screen and (max-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: props.less ? '1050px' : '1220px',
      padding: props.less ? '0 10px' : '0px',
    },
  };
  return result;
});

export default Contain;
